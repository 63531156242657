import React ,{ useState }  from "react"
import { navigate } from "gatsby"
import { handleLogin } from "../services/auth"
import classNames from "classnames"
import Alert from "./alert"

const Login = props => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")  
    const [showAlert,setShowAlert] = useState(false);
    const [message,setMessage] = useState("");
    const [isLoading, setLoading] = useState(false)

    const closeAlertStep= event => {
      setShowAlert(false)
    }
    function handlePassword(event) {
        event.preventDefault()
        setPassword(event.target.value)
    }
    
    function handleUsername(event) {
      event.preventDefault()
      setUsername(event.target.value)
    }

    function handleSubmit(event){
      event.preventDefault()
      setLoading(true);
      handleLogin(username,password, function(err,res){
        setLoading(false)
        if (err) {
          setShowAlert(true);
          setMessage(err)
          console.log("error d'autenticació= "+err.errorMessage)
        }else {
             setShowAlert(false);
             setMessage("")
            // console.log("logged!!!!" +err)
             navigate("/mainMenu")
        }
    })
}

   
    return (
      <section className="section login">
        <div className="field-wrapper">
          <input className={classNames(username && 'dirty','field-input')} type="text" name="username" required  onChange={handleUsername} />
          <label htmlFor="username">Username<abbr title="required" aria-label="required">*</abbr></label>
        </div>
        <div className="field-wrapper">
          <input className={classNames(password && 'dirty','field-input')} type="password" name="password" required onChange={handlePassword} />
          <label htmlFor="password">Password<abbr title="required" aria-label="required">*</abbr></label>
        </div>
        <button className="btn-primary btn--sm btn--block" onClick={handleSubmit}>Login</button>
        { isLoading && (
              <div className="overlay-loading"></div>
          )}
        { showAlert && 
          <Alert type="error">
          <div className="alert-message">
            {message}
          </div>
          <span className="alert-close" onClick={closeAlertStep}>
            <i className="icon-close"></i>
          </span>
        
          </Alert>
        }
      </section>
    )
}

export default Login