import React from "react"
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import Login from "../components/login"

export default () => (
  <React.Fragment>
    <div className="granjatec-wrapper">
      <Header/>
      <HeaderMenu />
      <div className="main-container">
          <Login />
         
      </div>
      <Footer/>
    </div>
 </React.Fragment>
)
