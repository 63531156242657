import React from 'react'
import classNames from "classnames"

const Alert = ({children, type}) => {
    return (
        <div className={classNames('alert', type && 'alert-'+type)}>
            <div className="alert-icon">
                <i className={'icon-'+type}/>
            </div>
            {children}
        </div>
    )
}

export default Alert